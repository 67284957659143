
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        
















































































































































.grid-cards {
  width: 100%;

  ::v-deep {
    .card {
      height: 100%;
      animation: fade-in linear 0.3s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid-cards__title {
  margin-bottom: 5rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid $c-black;
}

.grid-cards__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 4rem;

  @include mq(m) {
    grid-template-columns: repeat(4, minmax(220px, 1fr));
  }
}

.grid-cards__btn {
  padding: 1rem 2rem;
  border-radius: 99em;
  transition: background-color 0.15s, color 0.15s, border 0.15s;
  border: 1px solid #d8d8d8;
  font-size: 1.5rem;
  line-height: 1;
}

.grid-cards__btn:hover {
  cursor: pointer;
  background-color: #f04700;
  color: #fff;
  border-color: transparent;
}

.grid-cards__action {
  display: flex;
  justify-content: center;
}

.news {
  ::v-deep {
    .main-title__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 28px, m: 48px));
    }
  }
}

.news-details__picture {
  margin-bottom: 7rem;

  img {
    width: 1000%;
  }
}

.news-details__wrapper {
  display: flex;
  padding-bottom: 2rem;
}

.news-details__sidebar {
  display: none;

  @include mq(m) {
    display: block;
    width: col(4, 16);
    padding-right: col(1, 16);
  }
}

.news-details__content {
  flex: 1;

  ::v-deep {
    h2 {
      @extend %fw-medium;
      @include fluid-type(18, 24);
      // prettier-ignore
      @include fluid(margin-top, (xxs: 70px, m: 120px));
      @include fluid(
        margin-bottom,
        (
          xxs: 22px,
          m: 20px,
        )
      );

      margin-top: 12rem;
      font-family: $ff-default;
      line-height: 1.3;

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      @extend %fw-light;
      @include fluid-type(18, 24);
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 28px, m: 26px));

      font-family: $ff-default;
      line-height: 1.5;
    }

    p {
      @include fluid-type(15, 18);
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 28px, m: 32px));

      color: $c-gray-darker;
      line-height: 1.7;
    }
  }
}

.list {
  font-family: #{$ff-default};
  font-size: 1.5rem;
  font-weight: 400;
}

.list__title {
  color: $c-main-primary;
}


          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



















.contact {
  overflow: hidden;
  border-top: 1px solid $c-gray-light;
  border-bottom: 1px solid $c-gray-light;
}

.contact-inner {
  padding-right: 0;
  padding-left: 0;
}

.content {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 65px, m: 130px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 30px, m: 100px));

  width: 100%;
  padding-right: col(2, 24);
  padding-left: col(2, 24);
  border-bottom: 1px solid $c-gray-light;

  @include mq(m) {
    width: col(10, 24);
    border-right: 1px solid $c-gray-light;
    border-bottom: 0;
  }

  @include mq(wrapper) {
    width: col(8, 24);
  }
}

.cta {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 65px, m: 130px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 30px, m: 100px));

  position: relative;
  width: 100%;
  background-color: $c-press-bg;

  ::v-deep {
    .cta-rich {
      padding: 0 col(2, 24);
    }

    .content__icon {
      // prettier-ignore
      @include fluid(width, (xxs: 36px, m: 48px));
      // prettier-ignore
      @include fluid(height, (xxs: 36px, m: 48px));
      // prettier-ignore
      @include fluid(margin-bottom, (xxs: 13px, m: 18px));

      fill: $c-orange;
    }

    .content__title {
      // prettier-ignore
      @include fluid(font-size, (xxs: 24px, m: 36px));
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 100%;
    box-sizing: content-box;
    width: 100vw;
    height: 100%;
    background-color: $c-press-bg;
  }

  @include mq(m) {
    width: col(14, 24);
    padding-right: 0;
    padding-left: 0;

    ::v-deep .cta-rich {
      padding: 0 col(1, 9);
    }
  }

  @include mq(wrapper) {
    width: col(16, 24);

    ::v-deep .cta-rich {
      padding: 0 col(1, 7);
    }
  }
}

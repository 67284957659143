
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        




























.share__title {
  padding-bottom: 1.5rem;
  color: $c-main-primary;
  font-family: #{$ff-default};
  font-size: 1.5rem;
  font-weight: 400;
}

.share__items {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 1.5rem;
  list-style: none;
  border-bottom: 1px solid $c-gray-light;
}

.share__item {
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: $c-black;
    text-decoration: none;

    .icon {
      transition: all 0.3s;
    }

    &:hover {
      .icon {
        fill: $c-main-primary;
      }
    }
  }
}

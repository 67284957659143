
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































.contact-panel {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 60px, xxl: 140px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 50px, xxl: 100px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 50px, xxl: 100px));
}

.box {
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, xxl: 40px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, xxl: 40px));

  display: flex;
  align-items: flex-start;
  border: 1px solid $c-gray-light;
}

.box__icon {
  width: 3.3rem;
  height: 3.3rem;
  margin-right: 2rem;
  fill: $c-orange;
}

.box__link {
  display: block;
  color: $c-orange;
}


          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















































$breakpoint: xl;

.card {
  position: relative;
  display: block;

  @include mq(#{$breakpoint}) {
    min-width: 24rem;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $c-white;
  box-shadow: 0 5px 50px rgba($c-black, 0.1);
}

.picture {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }

  img {
    @include image-fit;

    transition: transform 0.2s;
  }

  .card:hover & {
    img {
      transform: scale(1.04);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 3rem 4rem;
}

.content__date {
  color: $c-gray;
  font-size: 1.1rem;
}

.content__title {
  color: $c-black;
}

.content__excerpt {
  font-size: 1.5rem;
  line-height: 1.1;

  ::v-deep {
    p {
      margin-bottom: 1.5rem;
    }
  }
}

.content__readmore {
  @extend %fw-medium;

  display: flex;
  align-items: center;
  margin-top: auto;

  .icon {
    margin-left: 0.5rem;
    fill: $c-main-primary;
    transition: transform 0.2s;
  }

  .card:hover & {
    .icon {
      transform: translateX(1rem);
    }
  }
}

.content__link {
  @include get-all-space;

  ::v-deep {
    .action__label {
      display: none;
    }
  }
}


          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






























.social-network {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
}

.social-network__heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.social-network__icon {
  // prettier-ignore
  @include fluid(width, (xxs: 36px, m: 48px));
  // prettier-ignore
  @include fluid(height, (xxs: 36px, m: 48px));
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 13px, m: 18px));

  fill: $c-orange;
}

.social-network__title {
  @extend %fw-light;
  // prettier-ignore
  @include fluid(margin-bottom, (xxs: 36px, m: 48px));
  // prettier-ignore
  @include fluid(font-size, (xxs: 24px, m: 36px));

  font-family: $ff-alt;
  line-height: 1.2;

  [class*='cta-rich--'][class*='--large'] & {
    // prettier-ignore
    @include fluid(font-size, (xxs: 24px, m: 46px));
  }
}

.social-network__items {
  display: flex;
  align-items: center;
  list-style: none;
}

.social-network__item {
  padding-right: 1rem;
  transition: all 0.3s;

  &:hover {
    fill: $c-orange;
  }
}

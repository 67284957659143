
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






































.grid-cards {
  width: 100%;

  ::v-deep {
    .card {
      height: 100%;
      animation: fade-in linear 0.3s;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid-cards__title {
  margin-bottom: 5rem;
  padding-bottom: 1.5rem;
  border-bottom: 2px solid $c-black;
}

.grid-cards__items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 4rem;

  @include mq(m) {
    grid-template-columns: repeat(4, minmax(220px, 1fr));
  }
}

.grid-cards__action {
  display: flex;
  justify-content: center;

  ::v-deep {
    .action--btn,
    .action--btn--link {
      color: $c-black;
      background-color: transparent;
      border: 1px solid $c-gray-light;

      &:hover {
        color: $c-white;
        border-color: transparent;
      }
    }
  }
}
